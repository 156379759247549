import React from "react"
import { Helmet } from "react-helmet"
import ProductsNew from "../components/goods/ProductsNew"

const ProduktyPage = ({ pageContext, location }) => {
  let query = "goods/items/"
  let page = 1

  let path
  let cat
  path = location.pathname.split("/")
  if (path[2] !== "strana" && path[2] !== "detail") {
    cat = path[2]
  }

  if (path[2] === "strana") {
    page = parseInt(path[3])
  }
  if (path[3] === "strana") {
    page = parseInt(path[4])
  }

  const urlParams = new URLSearchParams(location.search)
  const producer = urlParams.get("v")
  let orderBy = []
  orderBy.push({ updated: "DESC" })

  let params = []
  params.push("priceVat:>:0")
  producer && params.push("producer:" + producer)

  if (cat && cat !== "") {
    query = query + cat + "/"
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Švédské potraviny</title>
      </Helmet>
      <ProductsNew
        location={location}
        paging={16}
        query={query}
        params={params}
        orderBy={orderBy}
        page={page}
        producer={producer}
        cat={cat}
      />
    </>
  )
}

export default ProduktyPage
